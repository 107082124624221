import React from 'react'
import Helmet from 'react-helmet'
import { Link, StaticQuery, graphql } from 'gatsby'
import { MessageAltError } from '@styled-icons/boxicons-solid'

import Layout from '../components/Layout'

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query NotFoundPageQuery {
        globalSettings: settingsYaml {
          siteTitle
        }
      }
    `}
    render={(data) => (
      <Layout>
        <Helmet>
          <title>404 – Page Not Found</title>
        </Helmet>
        <section
          className="section thick"
          style={{
            height: '85vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div className="container skinny taCenter" style={{}}>
            <p>
              <MessageAltError size="5rem" />
            </p>
            <h1>404 - Page Not Found</h1>
            <p>
              We can't find the page you are looking for!
              <br />
              Head back to <Link to="/"> Home Page</Link>
            </p>
          </div>
        </section>
      </Layout>
    )}
  />
)
